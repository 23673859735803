import React from "react";
import withAuth from "./withAuth";
import withSession from "./withSession";
import ModuleWrapper from "./ModuleWrapper";
import modules from "./modules";
//const ModuleContainer = React.lazy(() => import("./Components/ModuleContainer"));
/*
const LoginContainer = React.lazy(() => import("./Components/LoginContainer"));
const LogoutContainer = React.lazy(() => import("./Components/LogoutContainer"));
const RegistrationContainer = React.lazy(() => import("./Components/RegistrationContainer"));

const LandingContainer = React.lazy(() => import("./Components/LandingContainer"));
const MainContainer = React.lazy(() => import("./Components/MainContainer"));
const ContentContainer = React.lazy(() => import("./Components/ContentContainer"));
const ForgotContainer = React.lazy(() => import("./Components/ForgotContainer"));
const ResetContainer = React.lazy(() => import("./Components/ResetContainer"));
const Edukasi = React.lazy(() => import("./Components/edukasi"));
*/
const home = React.lazy(() => import("./pages/App"));
const login = React.lazy(() => import("./pages/Login"));
const profile = React.lazy(() => import("./pages/Profile"));
const peternak = React.lazy(() => import("./pages/Peternak"));
const pedagang = React.lazy(() => import("./pages/Pedagang"));
const agen = React.lazy(() => import("./pages/Agen"));
const pemotongan = React.lazy(() => import("./pages/Pemotongan"));
const paster = React.lazy(() => import("./pages/PasarTernak"));
const detail = React.lazy(() => import("./pages/Detail"));
const detailById = React.lazy(() => import("./pages/DetailById"));
const owner = React.lazy(() => import("./pages/PemilikTernak"));
const rph = React.lazy(() => import("./pages/RPH"));
const rph_detail = React.lazy(() => import("./pages/RPHDetail"));
const pasang_iklan = React.lazy(() => import("./pages/PasangIklan"));
const bumdes = React.lazy(() => import("./pages/Bumdes"));
const edukasi = React.lazy(() => import("./pages/Edukasi"));
const detil_edukasi = React.lazy(() => import("./pages/EdukasiDetil"));

const registrasi = React.lazy(() => import("./pages/Registrasi"));
const lupa = React.lazy(() => import("./pages/Lupa"));
const reset = React.lazy(() => import("./pages/ResetPassword"));
const potensi = React.lazy(() => import("./pages/Potensi"));

/*
const getModules = () => {
  let routes = [];
  return modules.map((item, index) => {
    return {
      path: "/" + item.slug,
      exact: true,
      component: item.crud
        ? withAuth(ModuleWrapper(ModuleContainer, item, modules))
        : withAuth(ModuleWrapper(item.component, item, modules))
    };
  });
};*/
const routes = [
 
  {
    path: "/",
    exact: true,
    component: withSession(home)
  },
  {
    path: "/login",
    exact: true,
    component: withSession(login)
  },
  {
    path: "/registrasi",
    exact: true,
    component: withSession(registrasi)
  },
  {
    path: "/lupa",
    exact: true,
    component: withSession(lupa)
  },
  {
    path: "/reset",
    exact: true,
    component: withSession(reset)
  },
  {
    path: "/profile",
    exact: true,
    component: withAuth(profile)
  },
  {
    path: "/registrasi-peternak",
    exact: true,
    component: withSession(peternak)
  },
  {
    path: "/registrasi-pedagang",
    exact: true,
    component: withSession(pedagang)
  },
  {
    path: "/registrasi-agen",
    exact: true,
    component: withSession(agen)
  },
  {
    path: "/daftar-pemotongan",
    exact: true,
    component: withAuth(pemotongan)
  },
  {
    path: "/paster",
    exact: true,
    component: withSession(paster)
  },
  
  {
    path: "/detail/:id",
    exact: true,
    component: withSession(detailById)
  },
  
  {
    path: "/peternak/:id",
    exact: true,
    component: withSession(owner)
  },
  {
    path: "/rph",
    exact: true,
    component: withSession(rph)
  },
  {
    path: "/rph-detail",
    exact: true,
    component: withSession(rph_detail)
  },
  {
    path: "/pasang-iklan",
    exact: true,
    component: withAuth(pasang_iklan)
  },
  {
    path: "/bumdes",
    exact: true,
    component: withSession(bumdes)
  },
  {
    path: "/edukasi",
    exact: true,
    component: withSession(edukasi)
  },
  {
    path: "/edukasi/:id",
    exact: true,
    component: withSession(detil_edukasi)
  },
  {
    path: "/potensi",
    exact: true,
    component: withSession(potensi)
  },
  // {
  //   path: "/edukasi",
  //   exact: true,
  //   component: Edukasi
  // },
  // {
  //   path: "/yanlik",
  //   exact: true,
  //   component: LandingContainer
  // },
  // {
  //   path: "/penyelenggara",
  //   exact: true,
  //   component: ModuleWrapper(ContentContainer, {
  //     slug:'penyelenggara'
  //   }, modules)
  // },
  // {
  //   path: "/tentang",
  //   exact: true,
  //   component: ModuleWrapper(ContentContainer, {
  //     slug:'tentang'
  //   }, modules)
  // },
  // {
  //   path: "/pedoman",
  //   exact: true,
  //   component: ModuleWrapper(ContentContainer, {
  //     slug:'pedoman'
  //   }, modules)
  // },
  // {
  //   path: "/kontak",
  //   exact: true,
  //   component: ModuleWrapper(ContentContainer, {
  //     slug:'kontak'
  //   }, modules)
  // },
  // {
  //   path: "/faq",
  //   exact: true,
  //   component: ModuleWrapper(ContentContainer, {
  //     slug:'faq'
  //   }, modules)
  // },
  // {
  //   path: "/petunjuk",
  //   exact: true,
  //   component: ModuleWrapper(ContentContainer, {
  //     slug:'petunjuk'
  //   }, modules)
  // },
  // {
  //   path: "/pengumuman",
  //   exact: true,
  //   component: ModuleWrapper(ContentContainer, {
  //     slug:'pengumuman'
  //   }, modules)
  // },
  // {
  //   path: "/login",
  //   exact: true,
  //   component: LoginContainer
  // },
  // {
  //   path: "/logout",
  //   exact: true,
  //   component: LogoutContainer
  // },
  // {
  //   path: "/register",
  //   exact: true,
  //   component: RegistrationContainer
  // },
  
  // {
  //   path: "/forgot",
  //   exact: true,
  //   component: ForgotContainer
  // },
  // {
  //   path: "/reset",
  //   exact: true,
  //   component: ResetContainer
  // },
 // ...getModules()
];

export default routes;
