import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import routes from "./routes";
//import './App.css';

const MainStyle = styled.div`
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background:var(--main-yellow-color);
}
}`;

function App(props) {
  let currentPath = window.location.pathname;
  let isAppBarShow = true;
  if (currentPath === "/login") isAppBarShow = false;

  return (
    <Suspense
      fallback={
        <div
          style={{ textAlign: "center", marginTop: window.innerHeight * 0.3 }}
        >
          <img
            src={"/images/logo.png"}
            style={{ marginBottom: 10, width: 150, marginLeft: 30 }}
          />
          <h4>Mohon Tunggu Sebentar...</h4>
        </div>
      }
    >
      <Router>
        <Switch>
        {routes.map((route, index) => (
          // Render more <Route>s with the same paths as
          // above, but different components this time.

          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
