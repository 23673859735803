const API_URL = process.env.REACT_APP_API_URI;
const qs = require("qs");

export default class Api {
  async post(url, data, withToken) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      : {
          "Content-Type": "application/json"
        };
    let response = await fetch(API_URL + url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      });
    return response;
  }

  async get(url, data, withToken) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      : {
          "Content-Type": "application/json"
        };

    let response = await fetch(API_URL + url + "?" + qs.stringify(data), {
      headers: headers
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      });
    return response;
  }

  async delete(url, data, withToken) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      : {
          "Content-Type": "application/json"
        };

    let response = await fetch(API_URL + url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: headers
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      });
    return response;
  }

  login(data) {
    return this.post("/login", data);
  }
  async modules(){
    
    let response = await fetch(API_URL + '/platform/modules', {
      
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      });
    return response;
  }
  register(data) {
    return this.post("/register", data);
  }
  questions(data) {
    return this.get("/questions", data, true);
  }
  result(data) {
    return this.post("/result", data, true);
  }
  async upload(url, data, withToken){
    const headers = withToken
      ? {
        
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      : {
        
        };

    const formData = new FormData();
    for(let k in data){
      formData.append(k, data[k]);
    }
    let response = await fetch(API_URL + url, {
      method: "POST",
      body: formData,
      headers: headers
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      });
    return response;
  }
  async call(opts, withToken) {
    console.log("Api.Call", opts, "WithToken:", withToken);
    const { endpoint, data, scenario } = opts;
    let body = data;
    
    let id = (data && typeof data.id !== "undefined") ? data.id : null;
    if (id !== null) delete body.id;

    switch (scenario) {
      case "pagination":
        return this.get( endpoint, body, withToken);
        break;
      case "read":
        return this.get( endpoint + "/" + id, body, withToken);
        break;
      case "insert":
        return this.post( endpoint, body, withToken);
        break;
      case "update":
        return this.post( endpoint + "/" + id, body, withToken);
        break;
      case "delete":
        return this.delete( endpoint + "/" + id, body, withToken);
        break;
      case "post":
        return this.post( endpoint, body, withToken);
        break;

      case "upload":
        return this.upload( endpoint, body, withToken);
      break;
      default:
       
        return this.get( endpoint, body, withToken);
        break;
    }
  }
  crud(opts) {
    const { endpoint, actionType, id, data } = opts;

    switch (actionType) {
      case "ADD":
        return this.post(endpoint, data, true);
        break;
      case "UPDATE":
        return this.post(endpoint + "/" + id, data, true);
        break;
      case "DELETE":
        return this.delete(endpoint + "/" + id, {}, true);
        break;
      case "GET":
        return this.get(endpoint + "/" + id, {}, true);
        break;
      case "LOOKUP":
        return this.get(endpoint + "/lookup", data, true);
        break;
      default:
        return this.get(endpoint, data, true);
        break;
    }
  }
  do_action(opts) {
    const { service, actionName, id } = opts;
    
    return this.get(service.endpoint + "/" + actionName.toLowerCase() + "/"+ id, {}, true);
  }
}
