import React, { Component } from "react";
export default function withSession(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        isLogin: false,
      };
    }
    componentDidMount() {
      let token = localStorage.getItem("token");
      let isLogin = token ? true : false;

      this.setState({
        isLogin,
      });
    }
    render() {
      console.log("withSession",{...this.props});
      return (
        <ComponentToProtect {...this.props} isLogin={this.state.isLogin} />
      );
    }
  };
}
